.notFound {
    @include verticalCenter;
    height: 95vh;
    max-width: 1200px;
    background: $color-3;
    border-radius: $borderRadius;
    text-align: center;

    h3 {
        margin-bottom: 3rem;
        font-size: 2rem;
    }
    a {
        padding: 1rem 2rem;
        border-radius: $borderRadius;
        background: $color-5;
        transition: 0.2s;

        &:hover {
            background: $color-4;
        }
    }
}
