.knowledges {
    .knowledgesContent {
        min-height: $min-height;
        width: 100%;
        position: relative;
        overflow: hidden;
        display: grid;
        grid-template-columns: 38% 1fr 1fr;
        grid-template-rows: 70% 30%;
        grid-template-areas:
            'L E E'
            'O O H';

        .languagesFrameworks,
        .experience,
        .otherSkills,
        .hobbies {
            background: $color-2;
            border-radius: $borderRadius;
            padding: 0.8rem;
        }
        h3 {
            font-size: 1.4rem;
            margin-bottom: 1rem;
            font-family: $font-2;
            color: $color-5;
            text-transform: uppercase;
        }
        .languagesFrameworks {
            grid-area: L;
            margin-bottom: 0.5rem;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
                'L'
                'F';

            .languageDsiplay {
                grid-area: L;
            }
            .frameworkDisplay {
                grid-area: F;
            }
            h3 {
                margin-bottom: 0.3rem;
            }
            .years {
                font-size: 0.65rem;
                display: flex;
                justify-content: space-between;
                color: $white;

                span {
                    width: 36%;

                    &:nth-child(2) {
                        text-align: center;
                        width: 20%;
                    }
                    &:nth-child(3) {
                        text-align: right;
                        width: 44%;
                    }
                    &:nth-child(2):before,
                    &:nth-child(3):before {
                        content: '';
                        background: rgba(180, 179, 179, 0.5);
                        position: absolute;
                        height: 146px;
                        width: 1px;
                        transform: translate(9px, 19px);
                    }
                }
            }
            .languagesList {
                padding-top: 0.5rem;

                li {
                    color: $color-1;
                }
                .progressBar {
                    height: 0.4rem;
                    background: transparent;
                    border-radius: 50px;
                    margin-top: 0.2rem;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        background: $color-3;
                        border-radius: 50px;
                        height: 0.4rem;
                        width: 0;
                        animation: line 2s ease-out forwards;

                        @keyframes line {
                            to {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .experience {
            grid-area: E;
            margin: 0 0 0.5rem 0.5rem;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 10% 1fr 1fr;
            grid-template-areas:
                '1'
                '2'
                '3';

            h3 {
                grid-area: 1;
            }
            h4 {
                font-size: 1.1rem;
                color: $color-5;
            }
            h5 {
                color: $color-3;
                padding: 0.5rem 0 0.5rem 1rem;
            }
            p {
                text-align: justify;
                padding: 0 1rem;
            }
            .exp-1 {
                grid-area: 2;
            }
            .exp-2 {
                grid-area: 3;
            }
        }
        .otherSkills {
            grid-area: O;
            margin-right: 0.5rem;

            .list {
                display: flex;
                width: 80%;
                height: 100%;
                margin: 0 auto;
                justify-content: space-between;

                ul {
                    height: 80%;
                    display: grid;

                    i {
                        color: $color-5;
                        padding-right: 0.2rem;
                    }
                }
            }
        }
        .hobbies {
            grid-area: H;
            padding-left: 1.5rem;

            ul {
                height: 80%;
                display: grid;

                li {
                    i {
                        position: absolute;
                        color: $color-5;
                    }
                    span {
                        position: absolute;
                        transform: translateX(2.2rem);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 950px) {
    .knowledgesContent {
        h3 {
            font-size: 1.2rem !important;
        }
        .languagesList {
            padding-top: 0.2rem !important;
        }
        p {
            font-size: 0.8rem;
        }
        .otherSkills,
        .hobbies {
            font-size: 0.8rem;
        }
    }
}
@media screen and (max-width: 850px) {
    .knowledgesContent {
        h4 {
            font-size: 0.9rem !important;
        }
        h5 {
            font-size: 0.6rem !important;
            padding: 0.2rem 1rem !important;
        }
    }
}
@media screen and (max-width: 500px) {
    .knowledgesContent {
        display: grid !important;
        grid-template-columns: 1fr !important;
        grid-template-rows: 1fr 1fr 180px 180px !important;

        .languages {
            grid-area: 1 !important;
        }
        .experience {
            grid-area: 2 !important;
            margin-left: 0 !important;
        }
        .otherSkills {
            grid-area: 3 !important;
            margin-right: 0 !important;
        }
        .hobbies {
            grid-area: 4 !important;
            margin-top: 0.5rem !important;
        }
    }
}
