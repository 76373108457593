@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&family=Josefin+Sans:wght@100&family=Nova+Round&display=swap');

$font-1: 'Nova Round', cursive;
$font-2: 'Dosis', sans-serif;
$font-3: 'Josefin Sans', serif;

$color-1: #2a1d52;
$color-2: #6c77f4;
$color-3: rgb(99, 164, 233);
$color-4: #4fedd2;
$color-5: #36d0ea;
$white: #ddd;
$black: #333;

$borderRadius: 25px;
$shadow: 0 1px 3px #333;
$min-height: 95vh;

@mixin verticalCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    margin: 1rem auto;
    max-width: 1200px;
    font-family: $font-1;
    color: $black;
}
button {
    cursor: pointer;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: $black;
    cursor: pointer;
}
a:visited {
    color: $black;
}
.home,
.knowledges,
.portfolio,
.contact {
    display: flex;

    @media screen and (max-width: 850px) {
        display: block !important;
    }
}

.homeContent,
.knowledgesContent,
.portfolioContent,
.contactContent {
    margin-left: 0.4rem;
    @media screen and (max-width: 850px) {
        margin-left: 0 !important;
    }
}
